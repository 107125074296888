* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
:root {
  --title-color: #464270;
  --white-color: #f7f6fb;
  --background-color: #ecf6fd;
  --primary: #1b5e20;
  --grey: #f1f0f6;
  --dark-grey: #8d8d8d;
  --light: #fff;
  --dark: #000;
  --green: #81d43a;
  --light-green: #e3ffcb;
  --blue: #1775f1;
  --light-blue: #d0e4ff;
  --dark-blue: #0c5fcd;
  --red: #fc3b56;
  --bordercolor: lightblue;
  --titlefontweight: 700;
  --green: darkgreen;
  --textWhite: white;
  --textfontsize: 15px;
  --headerfontsize: 20px;
  --containerboxshadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  --containerborderradius: 10px;
  --img-radius: 10px;
  --Icon-color: #678efe;
  --btn-border: #cdd9ed;
  --secondary: #b9f6ca;
  --black: #000;
  --white: #fff;
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #1b5e20;
  --first-color-light: #afa5d9;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --select-arrow: var(--select-border);
  --Backgound-color: #ecf6fd !important;
  --btn-color-green: #3a833a;
  --input-color: #99a3ba;
  --input-border: #cdd9ed;
  --border-color: #add8e6;
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #275efe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #eef4ff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --lbl-color: #3f4553;
  --Input-Font: "Mukta Malar", Arial;
  --Lato: "Lato", sans-serif;
  --Poppins: "Poppins", sans-serif;
  --Roboto: "Roboto", sans-serif;
  --hue: 223;
}
body {
  height: 100vh;
  width: 100%;
  background: var(--white-color);
  transition: background-color all 0.3s ease-in-out;
  font-family: var(--Roboto) !important;
}
#root {
  height: 100%;
  width: 100%;
  position: relative;
}
.Master-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}
.Master-card:first-child {
  width: 20%;
  height: 100%;
  background: white;
  padding: 50px 0;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.Master-card:last-child {
  width: 80%;
  height: 100%;
  padding: 50px 0 0 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.Master-card1 {
  width: 100%;
  height: 100%;
  padding: 50px 0 0 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.Master-wrapper {
  padding: 10px 20px 100px 10px;
  height: 100%;
  overflow: auto;
}
.Master-view {
  position: relative;
  width: 100%;
  height: 100%;
}
.btn-section {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  padding-right: 30px;
  row-gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5px;
  z-index: 1;
  height: auto;
  min-height: 50px;
  background-color: #f9f9fb;
  border-top: 1px solid #ddd;
}
.btn-sub-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
label {
  font-size: 12px;
  font-weight: 500;
  color: #3f4553;
}
input {
  outline: none;
}
.form-control {
  width: 100%;
  padding-left: 10px;
  font-size: 12px;
  height: 32px;
  color: black;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.form-control:focus {
  border: 1px solid rgba(81, 203, 238, 1);
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}
div:focus,
section:focus {
  outline: none !important;
}
::placeholder {
  font-size: 12px;
}
.select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  height: 30px;
  border-radius: 0.3em;
  color: white;
  font-size: 11px;
  border: none;
  min-width: 130px;
  transition: 0.5s;
  outline: none;
  text-align: center;
  background-color: var(--primary);
}
.btn-fabgreen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 14px;
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #3f4553 !important;
  border: 1px solid var(--btn-border) !important;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}
.btn-fabgreen:hover:not(:disabled),
.btn-fabgreen:focus:not(:disabled) {
  color: var(--white-color) !important;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.btn-fabgreen:disabled {
  border: lightpink;
  color: #3f4553;
  background-color: lightpink; /* Darker blue on hover */
}
.button-download {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  font-size: 13px;
  padding: 5px 10px;
  text-transform: capitalize;
  font-weight: normal;
  border: none;
  border-radius: 5px;
  color: #3f4553 !important;
  border: 1px solid var(--btn-border) !important;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}
.button-download:hover {
  color: var(--white-color) !important;
  background-color: var(--primary) !important; /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow */
}
.danger {
  color: red;
  margin: 0 2px;
}
.trancdisc {
  display: flex;
  position: relative;
}
.trancdisc input {
  border-radius: 5px 0 0 5px;
}
.trancdisc .btn-fabgreen {
  width: 50px;
  border-radius: 0 5px 5px 0;
  border-left: none;
  padding: 0;
  margin: 0;
  border: none !important;
}
.trancdisc .btn-fabgreen.active {
  color: var(--white-color) !important;
  background-color: var(--primary) !important; /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: white;
  cursor: pointer;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 10px;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body::-webkit-scrollbar {
  display: none;
}
::placeholder {
  font-size: 12px;
}
.m-header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0 10px;
}
.m-hsearch {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 32px;
}
.m-hsearch input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #add8e6;
  outline: none;
  padding: 0 10px;
  color: black;
  font-size: 13px;

  border-radius: 5px 0 0 5px;
}
.m-hsearch i {
  border-radius: 0 5px 5px 0;
  width: 30px;
  height: 100%;
  background: darkgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 14px;
  cursor: pointer;
}
.data-list {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.data-list button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-bottom: 0.5px solid #add8e6;
  transition: all 0.3s ease;
  background: transparent;
  word-wrap: break-word;
  word-break: break-all;
}
.data-list button label {
  color: black;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.data-list button img {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: white;
  border: 1px solid #add8e6;
  margin-right: 5px;
}
.data-list button i {
  color: darkgreen;
  opacity: 0.8;
  font-size: 12px;
  transition: all 0.3s ease;
}
.data-list button:hover,
.data-list button:focus {
  border-radius: 5px;
}
.data-list button:hover label,
.data-list button:focus label {
  color: var(--primary);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.data-list button i:hover {
  color: blue;
  font-weight: 600;
}
.flex-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #3f4553;
}
.flex-wrap1 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  font-weight: 500;
}
/* --------------------------------------- Unit Master ------------------------------ */
.unitmaster {
  display: grid;
  grid-template-columns: 200px auto;
  padding: 5px;
}
.unitlist,
.unit-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.unitlist:nth-child(1) {
  height: 100%;
}
.unit-wrapper {
  min-width: 200px;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  border-right: 1px solid var(--btn-border);
}
.unit-wrapper .dt-unit,
.unitlist .dt-unit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 0.5px solid #add8e6;
  transition: all 0.3s ease;
  background: transparent;
}
.unit-wrapper .dt-unit label,
.unitlist .dt-unit label {
  color: black;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.dt-header {
  background: var(--primary);
  width: 100%;
  font-size: 13px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 3px;
}
.dt-header,
.unitlist:nth-child(2) .dt-unit {
  white-space: nowrap;
  display: grid;
  grid-template-columns: 150px 50px 50px 50px 50px;
  column-gap: 10px;
  text-align: left;
}
.dt-header label {
  color: var(--white-color);
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}
.unitlist:nth-child(2) .dt-unit {
  text-align: center;
}
.unitlist:nth-child(2) .dt-unit label {
  text-align: left;
}
/* ==========================Line Template start================= */
.Print-Design .react-table {
  max-height: 250px;
}
.Print-Design .react-table .rt-tr .rt-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.Print-Col .react-table {
  max-height: 290px;
}
.Print-Col .react-table .rt-tr .rt-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.LineDesign {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.Line-Table .react-table {
  max-height: 505px;
}

.TransactionTable .react-table {
  max-height: 350px;
  min-width: 300px;
}
.table-Head-Check {
  display: flex;
  column-gap: 10px;
  padding-left: 10px;
}
.Print-Container {
  max-width: 600px;
  min-height: 520px;
  max-height: 520px;
  overflow: auto;
  border: 1px solid #d4d2d2;
  border-radius: 5px;
}
.Print-Container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.Print-Container::-webkit-scrollbar-track {
  background: white;
  border-radius: 2px;
}
.Print-Container::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 2px;
}
.Selected i {
  color: white;
}
.Selected input {
  border-radius: 2px;
  border: 1px solid rgb(3, 17, 23);
  background-color: #f9f5f5;
}
.Comp-Drop {
  display: inline-block;
}
.CompanyMenu-content {
  display: none;
  position: absolute;
  right: 55px;
  top: 65px;
  background-color: #f1f1f1;
  min-width: 170px;
  overflow: auto;
  font-size: 13px;
  border-radius: 7px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.Comp-Drop .CompanyMenu-Drop-Content:hover {
  background-color: darkgreen;
  color: white;
  cursor: pointer;
}
.CompanyMenu-content .CompanyMenu-Drop-Content {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  column-gap: 5px;
}

/* ---------------------------------------- E-Way Bill ----------------------------------------- */

.EB-Container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px 15px;

  font-weight: 700;
  background-color: #fff;
}
.btn-eb {
  position: relative;
  width: 100%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-eb button {
  border: none;
  border-radius: 5px;
  padding: 6px 12px;
  color: white;
  background: darkgreen;
  font-weight: 500;
  transition: 0.3s ease-in-out all;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
.btn-eb button:hover {
  font-weight: 600;
}

/* ================================= Customized Select Option Style ===================================== */
.custom-select {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  height: auto;
  border: none;
  gap: 0;
}
.custom-select .fa-caret-down {
  position: absolute;
  color: #3f4553;
  font-size: 12px;
  right: 10px;
  top: 10px;
}
.custom-option {
  position: absolute;
  top: 32px;
  z-index: 1003;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: auto;
  cursor: pointer;
  background: #fff;
  gap: 0;
}
.search-option {
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 3px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
}
.search-option input {
  margin: 0;
  min-height: 35px;
  height: 35px;
  width: 100%;
  border: 1px solid var(--btn-border);
  padding: 0 0 0 10px;
  color: black;
  font-weight: 500;
  border-radius: 5px;
}
.search-option input:focus {
  border: 1px solid var(--dark-blue);
}
.search-option span {
  color: gray;
  font-weight: 400;
  opacity: 0.9;
  text-transform: capitalize;
  width: 100%;
  font-size: 12px;
  text-align: left;
  padding: 5px;
  height: 100%;
}
.custom-option .option-list {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  max-height: 200px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  padding: 0 5px;
}
.custom-option .option-list button {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 10px;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: wrap;
}
.custom-option .option-list button:hover {
  color: var(--white-color);
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.custom-option .option-list button:hover label {
  color: var(--white-color);
}
.custom-option .option-list button:focus {
  color: var(--white-color);
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.custom-option .option-list button:focus label {
  color: var(--white-color);
}
.custom-option .option-list button:last-child {
  margin-bottom: 5px;
}
.custom-option .option-list .pro-con {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  border-bottom: 1px solid #add8e6;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.custom-option .option-list .pro-con .sub-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.custom-option .option-list .pro-con .sub-con label {
  color: black;
  font-weight: 600;
}
.custom-option .option-list .pro-con .sub-con:nth-child(2) {
  align-items: flex-end;
}
.custom-option .option-list .pro-con:hover label {
  color: var(--white-color) !important;
}
.custom-option .option-list .pro-con:focus {
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
}
.custom-option .option-list .pro-con:focus label {
  color: var(--white-color);
}
.custom-option .option-list button:hover label {
  color: var(--white-color);
}
/* --------------------------------------- Product Search Style ----------------------------------------------- */
.product-search .option-list {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px;
}
.product-search .option-list button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 7px 10px;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  transition: all 0.5s ease-in-out;
  background: transparent;
  cursor: pointer;
}
.product-search .pro-list button {
  display: grid !important;
  grid-template-columns: 30px auto 100px 100px 70px;
  justify-content: unset;
  align-items: center;
  text-align: left;
}
.product-search .option-list button label {
  text-transform: capitalize;
  color: black;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.product-search .option-list button label sub {
  margin-left: 10px;
  color: blueviolet;
  cursor: pointer;
}
.product-search .option-list button i {
  color: blueviolet;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.product-search .option-list button i:hover {
  transform: scale(1.5);
}
.product-search .option-list button:focus {
  background: darkgreen;
  border-radius: 5px;
}
.product-search .option-list button:hover label,
.product-search .option-list button:hover i {
  color: blueviolet;
}
.product-search .option-list button:focus label,
.product-search .option-list button:focus i,
.product-search .option-list button:focus label sub {
  color: var(--white-color) !important;
}
.product-search .option-list button:first-child {
  border-top: 1px solid #ddd;
}
.product-search .option-list button:last-child {
  border: none;
}
.option-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  outline: none;
  background: var(--primary) !important;
  padding: 0 20px;
  border-bottom: 1px solid var(--btn-border);
  border-radius: 10px 10px 0 0;
}
.option-header label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white-color) !important;
  font-weight: bold;
}
.option-header label:first-child,
.product-search .option-list button label:first-child {
  max-width: 50px;
  justify-content: center;
}
/* --------------------------------- Btn More Info ------------------------------ */
.btn-More {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  column-gap: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
  color: #2098ff;
  font-size: 12px;
}
.btn-More:hover,
.btn-More.active {
  color: blue !important;
}
/* ------------------------------------------------- Material Icons ----------------------------------- */
.material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  transition: all 0.5s ease;
}
.material-symbols-outlined:hover {
  transform: scale(1.2);
}
/* ===================================================Alert Message ========================================== */

.alertMsg {
  background: darkgreen;
  padding: 10px 15px;
  width: auto;
  right: 10px;
  top: 10%;
  position: absolute;
  border-radius: 4px;
  border-left: 5px solid #97bc62ff;
  border-right: 5px solid #97bc62ff;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  text-align: left;
  color: white;
  z-index: 9999;
}
.alertMsg .msg {
  font-size: 12px;
  max-width: 300px;
  word-break: break-all;
  color: white;
}
.alertMsg .msg::-webkit-scrollbar {
  display: none;
}
.alertMsg .close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
}
.alertMsg .close-btn:hover {
  background: white;
  border-radius: 5px;
  color: darkgreen;
  font-weight: bold;
}
.alertMsg.Show {
  animation: Show_slide 1s ease forwards;
}
.alertMsg.hide {
  display: none;
}
.alertMsg.hide {
  animation: hide_slide 1s ease forwards;
}
@keyframes Show_slide {
  0% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-10px);
  }
}
@keyframes hide_slide {
  0% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(0%);
  }

  80% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(100%);
  }
}
/* =========================================== Dialog Message Alert Style ======================= */
.Msg-Popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopupClose {
  display: none;
}
.Msg-Container {
  height: 150px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0 5px 5px 0 lightblue;
}
.Msg-Header {
  height: 35px;
  background: rgb(250, 251, 250);
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  border-radius: 10px;
  border-bottom: 1px solid rgb(230, 226, 226);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Msg-Header i {
  cursor: pointer;
  color: darkgreen;
}
.Msg-Header .MsgTooltip {
  display: none;
}
.Msg-Header .MsgClose {
  display: none;
}
.Msg-Header .MsgTooltip.active {
  display: block;
  z-index: 1;
  position: absolute;
  padding-top: 20px;
  margin-left: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #464270;
  border-radius: 7px;
  padding: 3px;
}
.Msg-Header .MsgClose.Close {
  position: absolute;
  z-index: 1;
  text-transform: capitalize;
  color: #464270;
  border-radius: 50%;
  font-size: 25px;
}
.Msg-Header i:nth-child(2):hover {
  color: #fff;
  border-radius: 50%;
  background-color: darkgreen;
}
.Msg-Body {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #464270;
  padding: 0 5px;
  white-space: wrap;
}
.Msg-Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  height: 30px;
  padding: 0 10px;
}
.Msg-Footer button {
  width: 100px;
  font-size: 15px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #464270;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  box-shadow: none;
  font-weight: 700;
}
.Msg-Footer button:hover {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}
.Msg-Footer button:focus {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}

/* -------------------------------------Icon Button and  TooolTip ------------------------------------ */
.icon-btn-grp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  column-gap: 10px;
}
.icon-btn {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: darkgreen;
  overflow: hidden;
  cursor: pointer;
}
.icon-btn i {
  transition: 0.2 linear;
  font-size: 12px;
}
.icon-btn button {
  background: transparent;
  border: none;
  outline: none;
  color: darkgreen;
}
.icon-btn:hover i,
.icon-btn:hover button {
  transform: scale(1.3);
  color: white;
}
.icon-btn::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: darkgreen;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.icon-btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
.icon-btn img {
  height: 20px;
  width: 20px;
  line-height: 20px;
  transition: 0.2 linear;
  font-size: 15px;
}
.icon-btn:hover img {
  transform: scale(1.3);
}
.icon-popup-btn-grp {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -5px;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  text-align: center;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}
.lbltooltip {
  position: relative;
  padding: 0;
}
.lbltooltip a span {
  border-radius: 10px;
  padding: 3px;
  border: 1px solid #ddd;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tooltiptext {
  position: absolute;
  top: 100%;
  left: -10px;
  padding: 2px 5px;
  background-color: black;
  color: var(--white-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  border-radius: 5px 3px 5px 3px;
  z-index: 100;
  display: none;
  white-space: nowrap;
}
.tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.lbltooltip:hover .tooltiptext {
  display: block;
}
.icon-btn-grp .tooltiptext {
  z-index: 999;
}
.vtooltip {
  position: relative;
  padding: 0;
}
.vtooltiptext {
  position: absolute;
  bottom: 80%;
  left: 0;
  padding: 2px 5px;
  background-color: black;
  color: var(--white-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  border-radius: 5px 3px 5px 3px;
  display: none;
}
.vtooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the top of the tooltip */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.vtooltip:hover .vtooltiptext {
  display: block;
  z-index: 999;
}
/* ------------------------------------------------POS Popup Screen --------------------------------------- */
.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999 !important;
  background-image: url("https://www.transparenttextures.com/patterns/lyonnette.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  position: relative;
  max-width: 70%;
  min-width: 40%;
  margin: auto;
  padding: 0;
  border-radius: 7px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.popup-content.auto {
  width: auto;
  max-width: 80%;
}
.popup-content.sub {
  max-width: 30%;
  min-width: 30%;
}
.popup-content.medium {
  min-width: 60%;
}
.popup-header {
  height: 50px;
  width: 100%;
  padding: 5px;
  background-color: #f9f9fb;
  border-bottom: 1px solid #ddd;
  border-radius: 7px 7px 0 0;
  display: flex;
  align-items: center;
}
.popup-header h5 {
  margin: 0;
  width: 100%;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 0 0 10px;
}
.popup-header .tab {
  width: 100%;
  position: relative;
  background: white;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-end;
  column-gap: 10px;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 10px 0;
}
.popup-header .tab label {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  width: 25%;
  color: black;
  position: relative;
  transition: 0.25s background ease;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
}
.popup-header .tab label:hover {
  color: blue;
}
.popup-header #tab-line {
  position: absolute;
  height: 3px;
  background: darkgreen;
  width: 30%;
  bottom: 0;
  left: 0;
  transition: 0.35s ease;
  border-radius: 2px 2px 0 0;
  z-index: 999;
}
.popup-body {
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 60vh;
  max-height: 60vh;
  overflow: auto;
}
.popup-card {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 60vh;
  height: auto;
  max-height: 60vh;
  padding: 0;
  border-right: 1px solid var(--btn-border);
  overflow: auto;
}
.popup-card .custom-select {
  position: relative;
  margin-top: 5px;
  padding: 0 5px;
}
.popup-card .m-hsearch {
  width: 100%;
  margin: 5px 0 0 0;
  padding: 0 5px;
  background: #fff;
}
.popup-card .m-hsearch input {
  height: 32px !important;
}
.popup-card .data-list {
  position: relative;
  margin-top: 5px;
}
.popup-body .row {
  margin: 0;
  padding: 0;
}
.popup-body .search-card {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 10px;
  padding: 5px 15px;
}
.popup-body .search-card input {
  width: 100% !important;
}
.popup-body .search-card.full {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}
.popup-footer {
  min-height: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  background-color: #f9f9fb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
  border-radius: 0 0 7px 7px;
}
.popup-btn-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  overflow: auto;
}
/*--------------------------------------- Product Search Style -----------------------------------------------*/
.table-card {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-height: 53vh;
  max-height: 53vh;
  overflow: auto;
}
.table-card .option-list {
  position: relative;
  width: 100% !important;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}
.table-card table thead {
  position: sticky;
  top: 0;
  margin: 0;
  padding: 0;
}
.table-card table thead tr {
  margin: 0;
  padding: 0;
  position: relative;
}
.table-card tbody tr:nth-of-type(odd),
.table-card tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-card td,
.table-card th {
  border: 0;
  border-bottom: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
  border-radius: 0 !important;
}
.table-card th {
  text-align: center;
  border-top: 1px solid #ddd !important;
  background: #f2f2f2 !important;
  font-weight: bold !important;
  padding: 5px;
  color: var(--lbl-color);
}
.table-card td {
  font-weight: 500;
  padding: 5px;
  font-size: 12px !important;
  word-wrap: break-word;
  word-break: break-all;
  z-index: 1;
}
.table-card tr:hover td {
  color: blueviolet;
}
.table-card tr:focus {
  outline: none;
}
.table-card tr:focus td {
  color: var(--white-color) !important;
  background: darkgreen;
}
.table-card button {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
  background: transparent;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin: 0;
  line-height: 30px;
}
.table-card .option-list button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-card button label {
  text-transform: capitalize;
  color: black;
  font-size: 13px;
  width: 100%;
  padding: 0 10px;
  margin: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-left: 0.5px solid var(--btn-border);
}
.table-card button label sub {
  margin-left: 10px;
  color: blueviolet;
  cursor: pointer;
}
.table-card button i {
  color: blueviolet;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.table-card button i:hover {
  transform: scale(1.5);
}
.table-card button:focus {
  background: darkgreen;
  border-radius: 5px;
}
.table-card button:hover label,
.table-card button:hover i {
  color: blueviolet;
}
.table-card button:focus label,
.table-card button:focus i,
.table-card button:focus label sub {
  color: var(--white-color) !important;
}
.option-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  outline: none;
  padding: 0 20px;
  border-top: 1px solid var(--btn-border) !important;
  border-bottom: 1px solid var(--btn-border) !important;
}
.option-header label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--lbl-color) !important;
  font-weight: bold;
}
.table-card button label:last-child {
  border-right: 0.5px solid var(--btn-border);
}
.table-card button:first-child {
  border-top: 1px solid #ddd;
}
.table-card button:last-child {
  border: none;
  border-bottom: 1px solid #ddd;
}
.option-header label:first-child {
  max-width: 50px;
  justify-content: center;
}
.table-card button label:first-child {
  max-width: 50px;
  justify-content: center;
}
/* ------------------------------------------- React Table style------------------------------------------ */
.react-table-container {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  overflow: auto;
}
.react-table-container.autotable {
  max-width: 100%;
  overflow: auto;
}
.react-table {
  position: relative;
  background-color: white;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  max-width: 100%;
}
.react-table.full-table {
  width: 100%;
  overflow: auto;
  max-width: 100%;
}
.react-table.auto-table {
  width: auto;
  overflow: auto;
  max-width: 100%;
}
.rt-thead {
  position: sticky;
  top: 0;
  background: var(--primary);
  z-index: 1;
}
.rt-tbody {
  background-color: white;
  overflow-x: hidden !important;
  border: 0.5px solid #ddd;
}
.rt-tr {
  position: relative;
  padding: 0;
  margin: 0;
}
.rt-th {
  border-right: 0.5px solid rgb(241, 246, 248) !important;
  color: var(--white-color);
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  white-space: nowrap !important;
}
.rt-td {
  cursor: pointer;
  font-size: 12px;
  position: relative;
  margin: 0;
  padding: 0 2px;
  border: 0 !important;
  height: 28px !important;
  max-height: 28px !important;
  border-bottom: 0.5px solid rgb(241, 246, 248) !important;
  border-right: 0.5px solid rgb(241, 246, 248) !important;
}
/* ------------------------------------------------ HTML TABLE style-------------------------------------------- */
table {
  position: relative;
  width: 100%;
  margin: 0;
}
th,
td {
  border: 1px solid rgb(241, 246, 248);
  padding: 3px;
  font-size: 12px;
  text-align: left;
  color: black;
}
th {
  position: sticky;
  top: 0;
  z-index: 2;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  padding: 5px;
  background: var(--primary);
  color: var(--white-color);
}
tbody tr:hover {
  background-color: #f2f2f2;
}
tfoot td {
  text-align: right !important;
  font-weight: 600;
  background: #fff;
}

/* ----------------------------------------------------------- Tax Screen Style -------------------------- */
.taxtable {
  width: 100%;
  margin-top: 10px;
  max-height: 80vh;
  overflow: auto;
  border-radius: 10px;
  background-color: white;
}
.tax-table {
  margin-top: 5px;
}
.tax-table i {
  color: #7a34ca;
}
.tax-table i:hover {
  color: blue;
}
.taxtable td:not(:nth-child(3)) {
  text-align: center;
}
.taxtable td:nth-child(1),
.taxtable th:nth-child(1) {
  width: 50px !important;
}
.taxtable td:nth-child(2),
.taxtable th:nth-child(2) {
  width: 50px !important;
  padding-left: 20px;
} /* ----------------------------------------------------------- Report Screen Style -------------------------- */
.data-list.rpt button label {
  text-transform: capitalize !important;
  font-size: 11px;
}
.report-wrapper {
  overflow: hidden;
  padding: 0 10px 140px 5px;
}
.report-header {
  padding: 3px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  margin-left: 1px;
}
.rport-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}
.rport-title h1 {
  color: black;
  font-size: 20px;
  font-weight: 600;
}
.report-table {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: scroll;
  border-radius: 10px;
}
.rpt-section {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 5px;
  margin: 0;
  width: 100%;
  column-gap: 20px;
  row-gap: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  height: auto;
  min-height: 40px;
  background-color: #f9f9fb;
  border-top: 1px solid #ddd;
}
.rpt-section p {
  color: var(--lbl-color);
  font-weight: bold;
  font-size: 13px;
  margin: 0;
}
.report-wrapper .table-card {
  width: 100%;
  max-height: 100%;
  min-height: 0;
}
#Reporttable {
  width: 100% !important;
  border-collapse: collapse;
}
#Reporttable th {
  cursor: pointer;
  background: var(--primary) !important;
  color: var(--white-color) !important;
  font-weight: 400 !important;
  font-size: 12px;
}
#Reporttable th.active {
  color: yellowgreen !important;
}
#Reporttable tfoot {
  display: none;
}
.Report-filter {
  width: 100%;
  margin-top: 10px;
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
  border-radius: 10px;
  border: none;
  background: transparent;
}
#FileUpload {
  display: flex;
  justify-content: center;
}
.filewrapper {
  margin: 30px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 415px;
}
.uploadfile {
  margin: 10px;
  height: 85px;
  border: 3px dashed #e6f5e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 12px;
  line-height: 0;
  font-size: 22px;
  color: #0c3214;
  letter-spacing: 1.5px;
}
.upload__button {
  background-color: #e6f5e9;
  border-radius: 10px;
  padding: 0px 8px 0px 10px;
}
.upload__button:hover {
  font-weight: bold;
  cursor: pointer;
  opacity: 0.8;
  color: var(--primary);
}
.uploadfile img {
  width: 30px;
  height: 30px;
}
.price-table {
  width: 100%;
  margin-top: 5px;
  max-height: 70vh;
  overflow: auto;
  border-radius: 10px;
  background-color: white;
}
/* -----------------------------Shipping address------------------------------------------------ */
.btn-shipadd {
  width: 100%;
  height: 35px;
  display: flex;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  background: none;
  color: blueviolet;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
}
.btn-shipadd:hover {
  font-weight: 700;
  text-decoration: underline;
}
.add-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 5px 10px;
  gap: 0.5rem;
}
.add-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
}
.add-cart h5 {
  width: 100%;
  font-size: 12px;
  color: black;
}
.add-cart h6 {
  width: 100%;
  font-size: 10px;
  color: black;
  font-weight: normal;
}
.add-cart h6 i {
  color: blueviolet;
  font-size: 10px;
  opacity: 0.6;
}
.btn-add {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

/* ----------------------------------------  404 page--------------------------- */
.page_404 {
  padding: 40px 0;
  background: #fff;
}
.page_404 img {
  width: 100%;
}
.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}
.four_zero_four_bg h1 {
  font-size: 80px;
}
.four_zero_four_bg h3 {
  font-size: 80px;
}
.link_404 {
  color: #fff !important;
  padding: 5px 20px;
  background: #39ac31;
  border-radius: 7px;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
/* ---------------------------------------- Tab style-------------------------------------------------- */
.fg-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 10px;
  margin: 3px 0;
}
.shadow-base {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.common-tab {
  width: 100%;
  position: relative;
  background: white;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  column-gap: 20px;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ddd;
}
.common-tab label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  text-align: center;
  color: black;
  position: relative;
  transition: 0.25s background ease;
  cursor: pointer;
  font-weight: normal;

  white-space: nowrap;
  text-transform: capitalize;
}
.common-tab img {
  height: 25px;
  width: 30px;
  margin-right: 5px;
}
.common-tab #tab-line {
  position: absolute;
  height: 2px;
  background: #1e88e5;
  bottom: 0;
  left: 0;
  transition: 0.35s ease;
  border-radius: 5px 5px 0 0;
}
.common-tab label.active {
  color: var(--blue);
}

.popup-tab {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  column-gap: 30px;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ddd;
  padding: 5px 0 0 10px;
  background-color: rgb(247, 246, 246);
  border-radius: 10px 10px 0 0;
}
.popup-tab::-webkit-scrollbar {
  display: none;
}
.popup-tab label {
  display: inline-block;
  font-size: 12px !important;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
  color: black;

  transition: 0.25s background ease;
}
.popup-tab label.active {
  color: darkgreen;
  font-weight: 600 !important;
}
.popup-tab #popup-tab-line {
  position: absolute;
  height: 3px;
  background: darkgreen;
  bottom: 0;
  left: 8px;
  transition: all 0.35s ease-in-out;
  border-radius: 5px 5px 0 0;
  min-width: 50px;
}
.trans-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--btn-border);
  background-color: rgb(247, 246, 246);
  padding: 5px 10px 0 10px;
  border-radius: 10px 10px 0 0;
}
.trans-buttons,
.transs-buttons {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  column-gap: 30px;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  border-radius: 10px 10px 0 0;
  margin-right: 10px;
}
.trans-buttons::-webkit-scrollbar,
.transs-buttons::-webkit-scrollbar {
  display: none;
}
.trans-buttons label,
.transs-buttons label {
  display: inline-block;
  font-size: 12px !important;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
  color: black;

  transition: 0.25s background ease;
}
.trans-buttons label.active,
.transs-buttons label.active {
  color: darkgreen;
  font-weight: 600 !important;
}
.trans-buttons #tab-line,
.transs-buttons #tabs-line {
  position: absolute;
  height: 3px;
  background: darkgreen;
  bottom: 0;
  left: 0;
  transition: all 0.35s ease-in-out;
  border-radius: 5px 5px 0 0;
  min-width: 50px;
}
.transh-hide,
.transs-hide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 25px;
  color: var(--primary);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  padding: 0;
  border: 1px solid var(--primary);
}
.transh-hide.active,
.transs-hide.active {
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
  color: var(--white-color);
  background-color: var(--primary);
}
.gstcol-h {
  color: var(--lbl-color);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--btn-border);
  height: 40px;
}
.emailtemplate {
  border: 1px solid var(--btn-border);
  border-radius: 10px;
  margin: 5px;
  padding: 0;
}
.emailtemplate h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid var(--btn-border);
  color: var(--lbl-color);
  font-weight: bold;
  height: 40px;
}

@media screen and (max-width: 1200px) {
  .m-hsearch {
    max-width: 50%;
    min-width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .Master-container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow: auto;
    height: auto;
    padding: 0;
  }
  .Master-card:first-child,
  .Master-card:last-child,
  .Master-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
  }
  .Master-wrapper {
    padding: 0 5px;
  }
  .data-list {
    max-height: 70vh;
  }
  .btn-section {
    position: relative;
    border-top: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }
  .popup-content,
  .popup-content.auto,
  .popup-content.sub,
  .popup-content.medium {
    position: relative;
    width: 95%;
    max-width: 95%;
  }
  .m-hsearch {
    max-width: 100%;
    min-width: 100%;
    margin: auto;
  }
  .rport-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
}
@media screen and (max-width: 769px) {
  .order-sm-1 {
    order: 2 !important;
  }
  .order-sm-2 {
    order: 1 !important;
  }
  .order-JE-2 {
    order: 2 !important;
  }
  .order-JE-1 {
    order: 1 !important;
  }
  .ProductShow {
    height: auto;
  }
  .Master-card {
    margin-top: 10px;
    padding: 0 10px 0 10px;
  }
}
@media screen and (min-width: 576px) {
  th {
    text-align: center;
  }
  .order-sm-2 {
    order: unset !important;
  }

  .order-sm-1 {
    order: unset !important;
  }
  .order-JE-2 {
    order: unset !important;
  }

  .order-JE-1 {
    order: unset !important;
  }
}
