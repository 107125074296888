.dashb-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ecf6fd;
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  padding-bottom: 50px;
}
.dashb-header {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  padding: 0 10px;
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
}
.dashb-header h1 {
  font-size: 20px;
  color: black;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.dashhead-input {
  display: flex;
  justify-content: flex-end;
}
.dash-date {
  position: relative;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  color: #8637f6;
  padding: 0 0 0 5px;
  border-radius: 5px;
  background: #fff;
}
.dash-date i {
  color: #8637f6;
  font-size: 13px;
  cursor: pointer;
}
.dash-date i:hover {
  color: blueviolet;
}
.dash-date span {
  font-size: 12px;
  color: #8637f6;
}
.dash-date .check {
  height: 100%;
  background-color: #8637f6;
  color: var(--white-color);
  font-weight: normal;
  padding: 4px 10px;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease;
}
.dash-date .check:hover {
  background: darkgreen;
  color: var(--white-color);
}
.datepicker-toggle {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 19px;
}
.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.dashb-content.first {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.3rem;
}
.dashb-content.secount {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 25% auto 25%;
  gap: 0.3rem;
}
.dashb-content.third {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.3rem;
}
.dashb-content .Cart {
  padding: 5px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto;
  align-items: flex-start;
  gap: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
}
.dashb-content .Cart1 {
  color: blue;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart2 {
  color: red;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart3 {
  color: green;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart4 {
  color: orange;
  padding: 10px;
  text-transform: uppercase;
}
.dashb-content .Cart .chart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.dashb-content .Cart .chart strong {
  font-size: 25px;
  font-weight: bold;
  color: black;
}
.dashb-content .Cart .chart span {
  font-size: 15px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.dashb-content .Cart .chart i {
  margin-right: 10px;
  font-size: 17px;
}
.dashb-content .Cart .chart .sal-up,
.dashb-content .Cart .chart .sal-down {
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
.dashb-content .Cart .chart .sal-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  align-items: flex-end;
  text-align: right;
}
.dashb-content .Cart .chart .sal-up {
  color: gray;
}
.dashb-content .Cart .chart .sal-down {
  color: red;
}
.dashb-content .Cart .chart .sal-cont span {
  font-size: 12px;
  font-weight: 400;
}
.Curr_Sales {
  width: 100%;
  padding: 5px 10px;
}
.Curr_Sales .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  white-space: nowrap;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border: 0.5px solid var(--btn-border);
}
.Curr_Sales .content strong {
  color: rgb(67, 11, 119) !important;
  font-weight: bold;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
}
.total_Count {
  margin: 0;
  padding: 0 10px;
  width: 100%;
}
.total_Count .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.total_Count .info span {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.total_Count .info strong {
  font-weight: bold;
  color: rgb(67, 11, 119) !important;
}
.Invoice-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
}
.Invoice-Container .invoice {
  padding: 0 10px;
}
.Invoice-Container .invoice:not(:last-child) {
  border-bottom: 1px solid rgb(241, 246, 248);
}
.Inv-Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.Inv-Content strong {
  font-size: 11px;
}
.Inv-Content span {
  color: gray;
  font-size: 10px;
}
.Inv-Content .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  font-size: 10px;
}
.Inv-Content .info small {
  font-weight: bold;

  font-size: 10px;
}
.Inv-Content .info .hover:hover {
  text-decoration: underline;
  color: blue;
}
.salesgrap-cont {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  display: block;
  border-radius: 7px;
  margin: 0;
  padding: 0;
}
.salesgrap {
  width: 100%;
  height: 250px;
  position: relative;
  font-size: 8px;
  transition: 0.3s ease-in-out;
}
.recharts-surface {
  padding: 0;
  margin: 0;
  margin-left: -30px;
}
.graptooltip {
  color: black;
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  font-size: 10px;
  padding: 5px;
  align-items: center;
  text-align: center;
  font-weight: normal;
}
.IncomegGraph-style {
  color: white;
  font-size: 10px;
  background-color: rgb(255, 255, 255);
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 300px;
  border: 1px dotted lightblue;
  border-radius: 5px;
  padding: 0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.Cart h3 {
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid rgb(241, 246, 248);
  color: black;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.Cart .out-amt {
  text-align: right;
}
.Cart table tr td:first-child {
  padding-left: 10px;
  white-space: nowrap;
}
.Cart table tr td:last-child {
  padding-right: 10px;
}
.Cart table tr th:first-child {
  padding-left: 10px;
}
.Cart table tr th:last-child {
  padding-right: 10px;
}
.sum-table {
  position: relative;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 0 !important;
  padding: 0 !important;
}
.sum-table h3 {
  margin: 0;
}
.tbl-container {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  color: black;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  border-spacing: 0;
}
.tbl-header {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  font-weight: 700 !important;
  color: rgb(67, 11, 119) !important;
  background: white !important;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.tbl-header span:not(:first-child) {
  text-align: right !important;
}
.tbl-body {
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0 10px;
}
.tbl-body p {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  font-weight: 600;
}
.tbl-body p span:not(:first-child) {
  text-align: right !important;
}
.tbl-footer {
  width: 100%;
  color: red;
  font-size: 12px;
  padding: 5px;
  text-align: left !important;
  font-weight: 700;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  padding: 0 10px;
}

@media (max-width: 900px) {
  .dashb-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dash-date {
    width: 100%;
  }
  .dashb-content.first {
    grid-template-columns: auto auto;
  }
  .dashb-content.secount {
    grid-template-columns: auto;
  }
  .dashb-content.third {
    grid-template-columns: auto;
  }

}
@media (max-width: 600px) {
  .dashb-content.first {
    grid-template-columns: auto;
  }
  .dashb-content.secount {
    grid-template-columns: auto;
  }
  .dashb-content.third {
    grid-template-columns: auto;
  }
}
