.Trans-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: block;
  overflow: hidden;
}
.Trans-card {
  width: 100%;
  height: 100%;
  padding: 50px 0 0 0;
  overflow: hidden;
}
.Trans-wrapper {
  padding: 10px 10px 50px 10px;
  height: 100%;
  overflow: auto;
}
.config-popup {
  max-width: 800px;
}
.config-popup .popup-body {
  min-height: 50vh;
  max-height: 50vh;
  padding: 10px;
}
.inv-no {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Icon-color);
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.inv-no:hover {
  color: var(--input-border-focus);
}
.sinv-no {
  position: relative;
}
.sinv-no span {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Icon-color);
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.sinv-no span:hover {
  color: var(--input-border-focus);
}
.config-text {
  font-size: 14px;
  text-align: left;
  color: var(--lbl-color);
  white-space: wrap;
}
.config-text1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
  text-align: left;
  color: var(--lbl-color);
  white-space: wrap;
}
.inv-check {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
.inv-check label {
  color: black;
  font-size: 13px;
  padding: 0;
  margin: 0;
}
.net-amount {
  padding: 0;
  border: 1px solid #ddd;
  min-height: 100px;
  border-radius: 5px;
}
.net-amount h1,
.net-amount h2 {
  width: 100%;
  min-height: 50px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}
.net-amount h1 {
  background-color: var(--primary) !important;
  color: var(--white-color);

  border-radius: 5px 5px 0 0;
}
.net-amount h2 {
  color: black;

  border-radius: 0 0 5px 5px;
}

.Transaction-Tabs .nav-item button {
  height: 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.Transaction-Tabs .nav-item .nav-link.active {
  background-color: darkgreen;
  color: white;
  border-radius: 10px 0 10px 0;
}
/* Trasaction Tab  */

.Discount-Container {
  overflow: auto;
}
.Summary-Discount {
  min-width: 400px;
  width: auto;
  display: flex;
  height: 100%;
  column-gap: 10px;
  font-weight: bold;
}
.Summary-Tax {
  overflow: auto;
}
.Summary-Tax table {
  border-radius: 5px;
}
.Summary-Tax table th {
  height: 30px;
  background-color: darkgreen;
  color: white;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  border-right: 1px solid white;
  white-space: nowrap;
}
.Summary-Tax table th:first-child {
  border-radius: 5px 0 0 5px;
}
.Summary-Tax table tbody {
  max-height: 200px;
  overflow: auto;
}
.Summary-Tax table th:last-child {
  border-radius: 0 5px 5px 0;
}
.Summary-Tax table tbody tr:last-child td:last-child {
  border-radius: 0 0 7px 0;
}
.Summary-Tax table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 7px;
}
.Summary-Tax table td {
  white-space: nowrap;
  height: 30px;
  background-color: rgb(190, 193, 190);
  color: rgb(12, 2, 2);
  font-weight: bold;
  padding-left: 10px;
  font-size: 13px;
  padding-right: 10px;
  border: 1px solid white;
}
.ConverRowMan {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 10px;
}
.ConverRowMan input {
  flex: 1 1 200px;
}
.ConverRowMan input:first-child {
  min-width: 200px;
}
.Trans-Input {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  background-color: #fff;

  font-weight: 700;
}
.Trans-Input input {
  flex: 1 0 100px;
}
.Trans-Input button {
  flex: 1 0 120px;
}
.Trans-Btn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 70px;
}
.Trans-Btn button {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  column-gap: 5px;
}
.JB_Tab_Btn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: auto;
  column-gap: 10px;
  border-radius: 5px;
  background-color: rgb(247, 246, 246);
}
#ExpIncHead {
  font-weight: 700;
}
#ExpIncSum {
  font-weight: 700;
}
.JB_SummaryTab_Btn {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: auto;
  column-gap: 10px;
  border-radius: 5px;
  background-color: rgb(247, 246, 246);
}
.JB_Tab_Btn button {
  padding: 0 10px;
  border: none;
  outline: none;
  font-size: 11px;
  width: 100px;
  height: 30px;
  background-color: rgb(247, 246, 246);
  white-space: nowrap;
  text-transform: uppercase;

  font-weight: 700;
  color: black;
}
.JB_SummaryTab_Btn button {
  padding: 0 10px;
  border: none;
  outline: none;
  font-size: 13px;
  width: 100px;
  height: 30px;
  background-color: rgb(247, 246, 246);
  white-space: nowrap;
  text-transform: uppercase;
  color: black;

  font-weight: 700;
}
.JB_SummaryTab_Btn .Show_Tab {
  outline: none;
  border-bottom: 2px solid darkgreen;
  color: darkgreen;

  font-weight: 700;
}
.JB_Tab_Btn .Show_Tab {
  outline: none;
  border-radius: 1px;
  border-bottom: 3px solid darkgreen;
  color: darkgreen;

  font-weight: 700;
  position: relative;
}
.JB_Tab_Items {
  padding: 10px 10px;
  background-color: #fff;

  font-weight: 700;
}
.JB_SummaryTab_Items {
  padding: 10px 10px;
  background-color: #fff;

  font-weight: 700;
}
.customizetab {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom: 2px solid lightblue;
  margin: 0 10px 0 -10px;
}

#Hide_Header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
#Hide-Summary {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  right: 5px;
  top: 0;
  cursor: pointer;
}
.AddFieldBtn {
  border: 1px dashed blue;
  color: blue;
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 10px;
  padding: 0 5px;
}
.AddFieldBtn:hover {
  background-color: blue;
  color: white;
  border: none;
}

#Discount_Tab {
  overflow-x: auto;
}
#Discount_Tab::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: white;
}
#Discount_Tab::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
#Discount_Tab::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: silver;
}

.Summary_Trans {
  display: flex;
  justify-content: space-between;
  row-gap: 30px;
  padding: 5px 20px;
  flex-wrap: wrap;
}

.dis {
  font-size: 12px;
  font-weight: 500;
}
.box-2 {
  max-width: 450px;
  padding: 10px 10px;
  border-radius: 7px;
  color: black;
}
.box-2 .box-inner-2 input.form-control {
  font-size: 12px;
  font-weight: 600;
}
.cardname {
  border-bottom: 2px dotted gray;
  font-weight: bold;
}
.TotalRecAmt {
  width: 100%;
  color: gray;
  border-radius: 0;
  text-align: center;
  font-weight: bold;
}
.TotalRecAmt input {
  text-align: center;
}
.box-2 .box-inner-2 .btn.btn-outline-primary {
  width: 120px;
  padding: 10px;
  font-size: 11px;
  padding: 0% !important;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  background-color: whitesmoke;
  color: black;
  font-weight: 600;
}
.box-2 .box-inner-2 .btn.btn-primary {
  background-color: #7700ff;
  color: whitesmoke;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: none;
  padding: 10px;
}
.box-2 .box-inner-2 .btn.btn-primary:hover {
  background-color: #7a34ca;
}
.box-2 .box-inner-2 .btn.btn-primary .fa {
  font-size: 13px !important;
  color: whitesmoke;
}

/* ----------------------------------  Transaction --------------------------------------------- */
.Trans-view {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-clip: border-box;
}
.tran-msgicon {
  position: absolute;
  top: 0;
  height: 32px;
  width: 30px;
  font-size: 20px;
  right: 30px;
  background: darkgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0;
  border-right: 1px solid #f1f1fa;
  color: white;
  cursor: pointer;
}
.tran-calicon {
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
  width: 30px;
  font-size: 20px;
  background: darkgreen;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.jtran-msgicon {
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
  width: 30px;
  font-size: 20px;
  background: darkgreen;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.tax-table {
  padding: 0;
  margin: auto;
  border-radius: 5px;
}
.tax-table td {
  border: 1px solid #ddd;
}
.tax-table i {
  color: #7a34ca;
}
.tax-table i:hover {
  color: blue;
}

/* ==================================Barcode Scan =================================== */
.BarcodInput input {
  position: absolute;
  max-width: 60px;
  max-height: 60px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
.bartable {
  max-height: 300px;
  overflow: auto;
}
.barprotable {
  max-height: 400px;
  overflow: auto;
}
.bartable::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: white;
}
.bartable::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.bartable::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: silver;
}
/* ========================== Address Add Click Button ============================================= */
.addAddress-btn {
  position: absolute;
  top: 0;
  right: 10px;
  border: 1px dashed blue;
  border-radius: 5px;
  color: blue;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.addAddress-btn:hover {
  color: white;
  background-color: blue;
}
.deliv-add i {
  color: darkgreen;
  cursor: pointer;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
/* ----------------------------------- Configuration Setting ----------------------------------------------- */
.Configsett {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
}
.Config-Container {
  width: 100%;
  margin: 0;
  background-color: #ffffff;
}
.sett-menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto;
  margin-bottom: 10px;
}
.sett-menu-cart {
  flex: 1 1 calc(100% / 5);
  cursor: pointer;
  background-color: #eee;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.sett-menu-cart h1 {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #3f4553;
}
.sett-menu-cart h1 i {
  font-size: 12px;
  font-weight: bold;
  display: none;
}
.sett-menu-cart ul {
  width: 100%;
  padding: 0;
}
.sett-menu-cart ul li {
  list-style: none;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
}
.sett-menu-cart h1:hover,
.sett-menu-cart ul li:hover {
  color: blueviolet;
}
.Config-Container.active {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 0;
  padding: 0;
}
.Config-Container.active .sett-menu {
  max-width: 250px;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  gap: 0;
  padding: 0;
  min-height: 0;
  max-height: 100vh;
  height: auto;
  overflow: auto;
  padding: 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
}
.Config-Container.active .sett-menu-cart {
  cursor: pointer;
  background: none;
  padding: 0 10px;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
}
.Config-Container.active .sett-menu-cart h1 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #3f4553;
}
.Config-Container.active .sett-menu-cart h1 i {
  position: absolute;
  display: block;
  right: 10px;
  transition: all 0.5s ease-in-out;
}
.Config-Container.active .sett-menu-cart ul {
  display: none;
  padding: 0;
}
.Config-Container.active .sett-menu-cart.active i {
  transform: rotate(90deg);
}
.Config-Container.active .sett-menu-cart.active ul {
  display: block;
}
.Config-Container.active .sett-menu-cart.active ul li {
  padding: 5px 0 5px 20px;
}

@media screen and (max-width: 1000px) {
  .JB_Tab_Btn {
    column-gap: 5px;
    white-space: nowrap;
  }
  .JB_Tab_Btn button {
    font-size: 10px;
    width: auto;
  }
  .JB_Tab_Items {
    padding: 5px;
  }
  .JB_SummaryTab_Btn {
    column-gap: 5px;
    white-space: nowrap;
  }
  .JB_SummaryTab_Btn button {
    font-size: 10px;
    width: auto;
  }
  .JB_SummaryTab_Items {
    padding: 5px;
  }
}
@media screen and (max-width: 900px) {
  .Trans-container {
    position: relative;
    height: auto;
    overflow: auto;
  }
  .Trans-card,
  .Trans-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }
  .btn-inv-group {
    position: relative;
    border-top: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }
  .disc-container {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .Summary_Trans {
    display: flex;
    flex-direction: column;
  }
  .Transaction-Tabs {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  .Transaction-Tabs .nav-item .nav-link {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .Transaction-Tabs .nav-item button {
    height: 35px;
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: center;
  }
  .Transaction-Tabs .nav-item .nav-link.active {
    background-color: darkgreen;
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }
  .container {
    max-width: 700px;
    margin: 10px auto;
  }
  #Hide_Header {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: -20px;
    cursor: pointer;
    column-gap: 15px;
  }
  .box-1,
  .box-2 {
    max-width: 600px;
    padding: 20px 10px;
    margin: 20px auto;
  }
  .box-1,
  .box-2 {
    max-width: 400px;
    padding: 20px 10px;
  }
  ::placeholder {
    font-size: 9px;
  }
}
