.JustBill_Home {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 40px 0 0 0;
  background: #fff;
}
.Nav-Menu {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 3;
  height: 50px;
  margin: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.Nav-Menu img {
  left: 10px;
  position: absolute;
  height: 40px;
  width: 150px;
}
#togglebar {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 1px 7px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 3px;
  color: var(--white-color);
  background: var(--primary);
  cursor: pointer;
  z-index: 1;
  transition: all 0.4s ease;
  display: none;
}
#togglebar.click {
  background: transparent;
  color: var(--primary);
}
#togglebar.click span:before {
  content: "\f00d";
}
#togglebar:hover {
  color: var(--white-color);
  background: var(--primary);
  font-size: 17px;
}
.Navmenu-item {
  height: 100%;
  margin: 0;
  padding: 0;
  padding-right: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 40px;
  cursor: pointer;
  background: #fff;
}
.Navmenu-item li {
  list-style: none;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.sub-tittle a {
  position: relative;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  color: #31934a;
  transition: all 0.3s ease-in-out;
}
.sub-tittle:hover a,
.sub-tittle.active a {
  color: var(--primary);
  transform: scale(1.1);
}
.sub-tittle .sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  z-index: 3;
  border-radius: 10px;
  background: #fff;
  white-space: nowrap;
  width: 250px;
}
.sub-tittle .sub-menu li {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 40px;
  padding: 0 10px;
}
.sub-tittle .sub-menu li:not(:last-child) {
  border-bottom: 1px solid #cdd9ed;
}
.sub-tittle .sub-menu li a {
  color: var(--dark);
  border-radius: 10px 0 10px 0;
}
.sub-tittle .sub-menu li:last-child a {
  border-radius: 10px 0 10px 10px;
}
.sub-tittle .sub-menu li a {
  position: relative;
  text-decoration: none;
  color: #3f4553;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  padding-left: 10px;
}
.sub-tittle .sub-menu li:hover a {
  background: darkgreen;
  color: white;
  padding-left: 20px;
}
.sub-tittle:hover .sub-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.Page_Header {
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 0 20px;
  margin: 0;
  line-height: 50px;
  column-gap: 20px;
}
.Page_Header hr {
  height: 2px;
  border: none;
  border-top: 1px solid #cdd9ed;
  width: 100%;
}
.Home_Content {
  width: 100%;
  min-height: 50vh;
  background-color: #ecf6fd;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 50px 10px 10px 10px;
  position: relative;
}
.Home_Content h1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: 700;
  font-size: 45px;
  color: darkgreen;
  text-transform: uppercase;
}
.Home_Content h2 {
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  color: black;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Home_Content p {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  line-height: 30px;
}
.btn-login {
  padding: 3px 9px;
  background: darkgreen;
  color: white;
  font-weight: normal;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
#JB_Modules {
  width: 100%;
  position: relative;
  padding: 0 20px;
  margin: 0;
}
.JB_Module_Container {
  width: 100%;
  margin: auto;
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  cursor: pointer;
  scroll-behavior: smooth;
  gap: 20px;
}
.JB_Modules_Box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 15px;
  background-color: #fff;
}
.JB_Modules_Box:hover {
  background-color: #faf5f5;
}
.JB_Modules_Box h1 {
  width: 100%;
  padding: 10px 0;
  background-color: darkgreen;
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 10px 10px 0 0;
}
.Module_Content {
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  white-space: nowrap;
}
.Module_Content h6 {
  line-height: 25px;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
#Pricing {
  width: 95%;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 600px;
}
#Pricing h3 {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}
.Pricing-Con {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 100px;
  row-gap: 30px;
}
.Pricing-Con .Prcing-Box {
  flex: 1 0 300px;
  max-width: 300px;
  min-height: 400px;
  border-radius: 7px;
  border: 1px solid lightblue;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  text-align: center;
  line-height: 35px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.Pricing-Con .Prcing-Box:hover {
  box-shadow: 0px -5px 10px 0px rgb(148, 208, 228);
  min-height: 430px;
  border: none;
}
.Pricing-Con .Prcing-Box:hover .Plan-btn {
  background-color: blue;
  color: white;
  font-weight: 700;
}
.Pricing-Con .Prcing-Box .Plan-Price {
  font-size: 25px;
  font-weight: 700;
}
.Pricing-Con .Prcing-Box .Plan-Price i {
  font-size: 15px;
}
.Pricing-Con .Prcing-Box .Plan-btn {
  margin-top: 30px;
  border-radius: 5px;
  width: 200px;
  height: 35px;
  border: 1px solid blue;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  transition: all 1.2s ease-out;
}
.Supported_Business {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 10px;
  gap: 10px;
}
.Supported-IconImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-right: 1px solid rgb(218, 236, 241);
}
.Supported-IconImage:last-child {
  border: none;
}
.Supported-IconImage img {
  height: 150px;
  width: 150px;
}
.Supported-IconImage label {
  white-space: nowrap;
  font-size: 11px;
  color: gray;
  font-weight: 700;
  text-transform: uppercase;
}
.Supported-IconImage:hover label {
  color: #289dd8;
  text-decoration: underline;
}
.JB_Feature {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 30px;
}
.Feature_Box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  background: #fff;
}
.la-img-wrapper {
  position: relative;
  width: 100%;
  border-radius: 15px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.la-img-wrapper img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  background: transparent;
}
.Feature_Box h5 {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  color: black;
  text-transform: uppercase;
  margin-top: 15px;
}
.Feature_Box p {
  margin-top: 0;
  width: 100%;
  font-size: 13px;
  text-align: left;
  white-space: wrap;
}
.Feature_Box:hover {
  background-color: #faf5f5;
}
footer {
  border-top: 0.5px solid var(--border-color);
  width: 100%;
  color: black;
  padding: 0;
  margin: 0;
}
footer .footer-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 10px 30px;
}
.footer-cont li {
  flex: 1 1 calc(100% / 3);
  list-style: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
}
.footer-cont li ul {
  padding: 0;
  margin: 0;
  line-height: 40px;
}
.footer-cont li ul li {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  margin: 0;
}
.footer-cont li h1 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}
.footer-cont li p,
.footer-cont li ul li a {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  color: black;
}
.footer-cont li p i {
  color: gray;
}
.Social-Icon {
  column-gap: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
}
.fa-facebook {
  color: #0165e1;
}
.fa-youtube {
  color: #c4302b;
}
.fa-linkedin {
  color: #0a66c2;
}
.fa-chrome {
  color: #4c8bf5;
}
.fa-instagram {
  font-size: 20px;
  color: transparent;
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.Copy-rights {
  border-top: 0.5px solid var(--border-color);
  color: #3f4553;
  display: flex;
  opacity: 1;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0;
}

/* ---------------------------------------- Menu Bar style--------------------------- */
.header {
  position: sticky;
  width: 100%;
  top: 0;
  padding: 10px;
  min-height: 40px;
  height: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 0;
  row-gap: 10px;
  align-items: center;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background: #fff;
  z-index: 2;
}
.header .ScreenName {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header .ScreenName h5 {
  height: 100%;
  display: flex;
  align-items: center;
  color: darkgreen;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.Header-Menu {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  text-align: center;
  column-gap: 10px;
  cursor: pointer;
  padding-right: 10px;
  height: 100%;
}
.Header-Menu a {
  font-size: 20px;
  color: darkgreen;

  font-weight: normal;
}
.Header-Menu a i {
  transition: all 0.4s ease-in;
  padding: 5px;
  border-radius: 10px;
}
.Header-Menu a:hover i {
  background-color: #d1eef8;
  color: #3f4553;
}
.menutooltip {
  position: relative;
  padding: 0;
  font-weight: normal;
}
.mtooltiptext {
  min-width: 60px;
  position: absolute;
  top: 100%;
  left: -40px;
  padding: 2px 5px;
  background-color: black;
  color: var(--white-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  border-radius: 5px 3px 5px 3px;
  z-index: 100;
  display: none;
}
.mtooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: 20px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.menutooltip:hover .mtooltiptext {
  display: block;
}
.Profile-Img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.4s ease-in-out;
}
.Profile-Img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.Profile-Img:hover {
  transform: scale(1.2);
}
.btn-bars {
  position: absolute;
  z-index: 999;
  top: 0;
  padding: 0;
  left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.4s ease-in-out;
}
.btn-bars span {
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
}
.btn-bars span:hover {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click {
  left: 225px;
}
.btn-bars.click span {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click span:before {
  content: "\f00d";
}
.right_menu {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  min-width: 250px;
  height: auto;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.right_menu::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  right: 9%;
  border-width: 20px;
  border-style: solid;
  margin-left: 5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.right_menu li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 40px;
  padding: 0 10px;
  transition: all 0.5s ease-in-out;
}
.right_menu li:not(:first-child) {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}
.right_menu li:not(:last-child) {
  border-bottom: 1px solid var(--btn-border);
}
.right_menu li:last-child {
  border-radius: 0 0 10px 10px;
}
.right_menu li:hover:not(:first-child) {
  padding-left: 20px;
  background-color: var(--primary);
  color: var(--white-color);
}
.profile-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-desc .img-wrapper {
  height: 80px;
  width: 80px;
  border-radius: 35px;
  margin-top: 10px;
  position: relative;
  border: 0.5px solid var(--btn-border);
  padding: 5px;
}
.profile-desc .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 35px;
}
.profile-desc .org-cont {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.profile-desc .org-cont h5 {
  font-size: 12px;
  line-height: 0;
  color: black;
}
.profile-desc .org-cont h5 span {
  color: gray;
  transition: all 0.3s ease-in-out;
}
.profile-desc .org-cont h5:hover,
.profile-desc .org-cont h5:hover span {
  color: #8637f6;
  font-weight: 500;
  text-decoration: underline;
}
.profile-desc .org-cont .yearlice {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-desc .org-cont .yearlice h5 {
  font-size: 12px;
  line-height: 0;
  color: black;
}
.profile-desc .org-cont .yearlice h5 span {
  color: gray;
  transition: all 0.3s ease-in-out;
}
.profile-desc .org-cont .yearlice h5:hover,
.profile-desc .org-cont .yearlice h5:hover span {
  text-decoration: none;
}
.profile-desc .org-cont .baclog {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
  column-gap: 20px;
}
.profile-desc .org-cont .baclog label {
  font-size: 12px;
  color: red;
  line-height: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profile-desc .org-cont .baclog label:hover {
  color: blueviolet;
}
.profile-desc .org-cont .baclog label i {
  color: gray;
  margin-right: 5px;
}
.right_menu.active {
  display: grid;
  grid-template-columns: auto;
}
.news-icon {
  position: relative;
}
.news-icon sub {
  position: absolute;
  right: 0px;
  top: 10px;
  font-size: 11px;
  color: black;
  font-weight: bold;
}
.news-container {
  display: none;
  position: absolute;
  top: 50px;
  right: 10px;
  min-width: 250px;
  max-width: 250px;
  height: auto;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.news-container.show {
  display: block;
}
.news-container::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 23%;
  border-width: 20px;
  border-style: solid;
  margin-left: 5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.news-container li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  font-size: 12px;
  color: #3f4553;
}
.news-container li:not(:last-child) {
  border-bottom: 0.5px solid var(--btn-border);
}
.news-container li p {
  text-align: left;
  text-wrap: wrap;
  line-height: 20px;
  margin: 0;
  color: black;
}
.sidebar {
  position: fixed;
  top: 0;
  width: 220px;
  height: 100%;
  left: -220px;
  background-color: var(--primary);
  transition: left 0.5s ease;
  overflow: auto;
  z-index: 9999;
}
.sidebar.show {
  left: 0;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.cart-rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.3s linear;
  color: #333;
}
.cart-rotate.cart-down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sidebar .logo {
  font-weight: bold;
  min-width: 200px;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding-left: 10px;
  text-align: left;
  background-color: var(--white-color);
  border-bottom: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 10px;
}
.logo-wrapper {
  width: 35px;
  height: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
  border: 1px dashed #ddd;
}
.logo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.sidebar .logo i {
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.sidebar .logo i:last-child {
  font-size: 15px;
  padding-top: 5px;
  border: none;
}
.sidebar .logo:hover {
  font-weight: bold;
  color: blue;
}
.sidebar ul {
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  list-style: none;
  padding-left: 0;
}
.nav-item {
  list-style: none;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  text-align: left;
  border-bottom: 1px solid #b9f6ca26;
}
.nav-item a {
  position: relative;
  width: 100%;
  text-decoration: none;
  font-size: 15px;
  height: 50px;
  color: var(--first-color-light);
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px auto 30px;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav-item a .fa-caret-down {
  float: right;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.3s linear;
}
.nav-item a:hover,
.nav-item.active a {
  color: var(--white);
  border-left: 3px solid var(--white);
  border-radius: 5px 0 0 5px;
  padding-left: 15px;
}
.nav-item a img {
  width: 22px;
  height: 22px;
  filter: invert(78%) sepia(13%) saturate(3207%) hue-rotate(300deg)
    brightness(1000%) contrast(80%);
}
.nav-item ul {
  display: none;
}
.nav-item.active ul {
  position: relative;
  background-color: #43a04754;
}
.nav-item.active ul li {
  list-style: none;
  text-decoration: none;
  border-bottom: none;
  white-space: nowrap;
}
.nav-item.active ul.show {
  display: block;
}
.nav-item.active ul li a {
  font-size: 13px;
  color: var(--first-color-light) !important;
  padding-left: 30px;
  color: var(--white);
  height: 40px;
  border-left-color: transparent;
  font-weight: 400;
  transition: all 0.3s ease;
}
.nav-item.active ul li a:hover {
  color: var(--white) !important;
  border-left-color: transparent !important;
  padding-left: 40px;
}
.nav-item a:hover .master-grid span {
  flex: 1 1 30%;
  border: 2px solid var(--white);
  border-radius: 2px;
}
.master-grid {
  width: 18px;
  height: 18px;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  row-gap: 2px;
}
.master-grid span {
  flex: 1 1 30%;
  border: 2px solid var(--first-color-light);
  border-radius: 2px;
}
#CompanyMaster {
  border-bottom: 1px solid #ddd;
}
.CompanyBox {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 15px;
  height: 80px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.FinancialYear {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 40px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.CompanyBox label {
  padding-top: 15px;
  font-weight: bold;
  font-size: 15px;
}
.CompanyBox button {
  border: 1px solid rgb(218, 206, 228);
  background-color: white;
  border-radius: 5px;
  width: 80px;
  height: 32px;
}
.CompanyBox i:hover {
  color: darkgreen;
  font-size: 20px;
}
.CompanyBox p {
  color: black;
  font-size: 12px;
}
.FHeader {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: darkgreen;
}
div.is-disabled {
  pointer-events: none;
}
.danger {
  color: red;
  margin: 0 2px;
}

/* ---------------------------------   Login Page Style--------------------------- */
.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.login-content {
  position: relative;
  color: black;
  background: #fff;
  padding: 0;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: grid;
  grid-template-columns: auto;
}
.login-Img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.login-Img img {
  width: 250px;
  height: 60px;
  object-fit: cover;
}
.login-header {
  display: block;
  line-height: 10px;
}
.login-header h6 {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
}
.login-header label {
  color: green;
  font-size: 15px;
  text-transform: capitalize;
}
.err-msg {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  word-wrap: wrap;
  white-space: wrap;
  text-align: left;
  color: red;
  font-size: 13px;
  font-weight: 500;
  animation: blinking 2.5s infinite;
  max-width: 300px;
}
.login-input {
  position: relative;
  padding: 0 10px;
  width: 100%;
}
.login-input label {
  font-weight: 400;
}
.login-usepass {
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 12px;
  background: #f5f3f3;
  border-right: 3px solid lightgray;
  border-left: 3px solid lightgray;
  transition: all 0.5s ease-in-out;
}
.login-usepass input {
  height: 35px;
  padding: 0 10px;
  border: none;
  outline: none;
  background: #f5f3f3;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border-left: 2px solid lightgray;
  min-width: 250px;
  max-width: 300px;
}
.login-usepass:hover {
  transform: scale(1.03);
}
.login-usepass input::placeholder {
  color: black;
  font-weight: 500;
}
.login-usepass i {
  font-size: 12px;
  color: green;
}
.login-btn {
  width: 100%;
  padding: 10px 10px 15px 10px;
}
.login-btn button {
  width: 90%;
  height: 40px;
  margin: auto;
  border: none;
  outline: none;
  background: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
}
.login-btn button:hover {
  background: var(--primary);
  color: var(--white-color);
  border: 1px solid yellowgreen;
}
.login_progress {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 7px;
  width: 97%;
  transform: scaleX(0);
  transform-origin: left;
  background-image: linear-gradient(to right, #539bdb, #3250bf);
  border-radius: 0 0 15px 15px;
  animation: load 0.5s 0.001s linear forwards;
}
.Autobackup {
  padding: 0 10px 10px 10px;
}
.Autobackup p {
  margin: 0;
  padding: 8px 0 0 0;
  font-size: 13px;
  color: black;
}
.aub-battom {
  margin-top: 10px !important;
  text-align: center;
}
@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes load {
  to {
    transform: scaleX(1);
  }
}
@keyframes blinking {
  0% {
    color: red;
  }
  47% {
    color: black;
  }
  62% {
    color: red;
  }
  97% {
    color: black;
  }
  100% {
    color: red;
  }
}
@media (max-width: 900px) {
  .header {
    grid-template-columns: auto;
  }
  .header .ScreenName,
  .Header-Menu {
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .btn-bars {
    font-size: 10px;
    padding: 0 5px;
  }
  #togglebar {
    display: block;
  }
  .Navmenu-item {
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    right: -250px;
    width: 250px;
    height: 100vh;
    cursor: pointer;
    transition: 0.5s ease all;
    border-radius: 10px 0 0 10px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: none;
  }
  .Navmenu-item.show {
    right: 0;
    display: flex;
    flex-direction: column;
  }
  .Navmenu-item.show li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    line-height: 40px;
    transition: all 0.4s ease;
  }
  .Navmenu-item.show li a {
    color: black;
    opacity: 0.9;
    transition: all 0.4s ease;
    font-weight: 500;
  }
  .Navmenu-item.show li:hover,
  .Navmenu-item.show li.active {
    background-color: var(--primary);
    border-radius: 5px;
  }
  .Navmenu-item.show li:hover a,
  .Navmenu-item.show li.active a {
    padding-left: 15px;
    color: var(--white-color);
  }
  .Navmenu-item.show .sub-menu li a {
    color: #3f4553;
  }
  .Navmenu-item.show li:first-child {
    border-radius: 10px 0 0 0;
  }
  .Navmenu-item li:hover hr,
  .Navmenu-item li.active hr {
    display: none;
  }
  .Navmenu-item .btn-login {
    width: 100%;
    border-radius: 10px;
    background-color: var(--primary) !important;
    font-weight: bold !important;
    color: var(--white-color) !important;
  }
  .Home_Content {
    padding-top: 30px;
  }
  .Home_Content h1 {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Home_Content h2 {
    width: 100%;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Home_Content p {
    width: 100%;
    text-align: left;
  }
  footer .footer-cont {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  footer ul li {
    text-align: center;
  }
  .footer-cont li ul li {
    text-align: center;
  }
  .footer-cont li p,
  .footer-cont li ul li a {
    text-align: center;
  }
  footer ul .Rpt-Btn {
    justify-content: center;
    text-align: center;
  }
  .call-Email {
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 700px) {
  .header {
    grid-template-columns: auto;
    justify-content: center;
  }
}
