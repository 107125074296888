.Company-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: first baseline;
}
.Company-content {
  position: relative;
  color: var(--lbl-color);
  font-weight: bold;
  background: #fff;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
  margin-top: 3%;
  min-width: 60%;
  max-width: 60%;
  padding-top: 20px;
}
.company-wrapper {
  min-height: 60vh;
  max-height: 60vh;
  overflow: auto;
  padding: 0 20px;
}
.Company-content .common-tab {
  padding-left: 20px;
}
.dt-company {
  display: grid;
  grid-template-columns: auto;
  margin-top: 20px;
  gap: 1rem;
  padding: 10px;
}
.dt-company .dt-button {
  white-space: nowrap;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: none;
  padding: 15px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 7px;
}
.dt-company .dt-button h1 {
  position: relative;
  text-align: left;
  width: 100%;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dt-company .dt-button.active h1 {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.dt-company .dt-button.selected h1 {
  color: darkgreen;
}
.dt-company .dt-button h1 .cmp-btns {
  width: 20%;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  font-size: 13px;
}
.dt-company .dt-button h1 .cmp-btns i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.dt-company .dt-button h1 .cmp-btns i:hover {
  color: blue;
  transform: scale(1.3);
}
.dt-company .dt-button h1 .cmp-btns .fa-caret-down {
  position: absolute;
  right: 10px;
}
.dt-company .dt-button.active h1 .cmp-btns .fa-caret-down {
  transform: rotate(-180deg);
  color: blueviolet;
}
.dt-company .dt-button .btn-section {
  display: none;
}
.dt-company .dt-button.active .btn-section {
  display: flex;
  padding-right: 10px;
}
.fyear-container {
  width: 100%;
  padding: 10px;
  transition: all 0.5s ease-in-out;
}
.ht-fyearh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: gray;
  border: 0;
  text-align: left;
  font-size: 13px;
}
.ht-fyear {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px auto;
  align-items: center;
  background: none;
  color: gray;
  border: 0;
  text-align: left;
  font-size: 13px;
  margin-left: 20px;
  margin-top: 20px;
}
.dt-fyear {
  width: 100%;
  color: var(--lbl-color);
  padding: 0;
  font-weight: 700;
}
.dt-fyear .fy-button {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px auto;
  align-items: flex-start;
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  line-height: 50px;
}
.dt-company .btn-section {
  position: relative;
}
.fy-button span {
  text-align: left;
}
.fy-button.selected {
  color: darkgreen;
}
.fy-button i {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.fy-button i:hover {
  color: blue;
  transform: scale(1.3);
}
.fy-button .fy-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.comp-logoutbtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}
.comp-logoutbtn button {
  border: none;
  outline: none;
  color: gray;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  background: none;
  transition: all 0.5s ease-in-out;
}
.comp-logoutbtn button i {
  font-size: 12px;
}
.comp-logoutbtn button:hover {
  color: blueviolet;
}
/* ------------------------------Fin Year and Key Message-------------------------- */

.Finkey h5 {
  padding: 0;
  height: 15px;

  font-weight: 700;
  color: darkgreen;
  margin-bottom: 20px;
}
.Finkey p {
  padding: 0;

  font-weight: 500;
  color: #3f4553;
  font-size: 14px;
}
.What_Phone {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.What_Phone span {
  width: 35px;
  height: 30px;
  border: 1px solid lightblue;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  background: #eef6ff;
  color: gray;
}
.What_Phone input {
  border-radius: 0 3px 3px 0;
}
/* ========================================  image Selection Style ============================== */
.img-container {
  width: 150px;
  height: 150px;
  display: flex;
  place-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: var(--img-radius);
  padding: 5px 10px;
  border: 1px solid #ddd;
  background: #fff;
}
.img-content {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: var(--img-radius);
}
.img-content .img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--img-radius);

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.img-content .img-wrapper .image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: --img-ravar(--img-radius);
}
.img-content .img-wrapper .image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--img-radius);
}
.img-content .img-wrapper .image i {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.img-content .img-wrapper #custom-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgreen;
}
.img-content .img-wrapper.active #custom-btn {
  display: none;
}
.img-content .img-wrapper .text {
  font-size: 10px;
  font-weight: 500;
  color: #5b5b7b;
}
.img-content .img-wrapper #cancel-btn i {
  position: absolute;
  font-size: 15px;
  right: 15px;
  top: 0;
  right: 0;
  height: 20px;
  width: 30px;
  background: darkgreen;
  color: white;
  cursor: pointer;
  display: none;
  border-radius: 0 0 0 20px;
}
.img-content .img-wrapper.active:hover #cancel-btn i {
  display: block;
}
.img-content .img-wrapper #cancel-btn i:hover {
  font-size: 17px;
}
.img-content .img-wrapper .file-name {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 0;
  font-size: 10px;
  background: white;
  color: gray;
  display: none;
  font-weight: normal;
}
.img-content .img-wrapper.active:hover .file-name {
  display: block;
}

/* ===================================================Alert Message ========================================== */

.alertMsg {
  background: darkgreen;
  padding: 10px 15px;
  width: auto;
  right: 10px;
  top: 10%;
  position: absolute;
  border-radius: 4px;
  border-left: 5px solid #97bc62ff;
  border-right: 5px solid #97bc62ff;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  text-align: left;
  color: white;
  z-index: 9999;
}
.alertMsg .msg {
  font-size: 12px;
  max-width: 300px;
  word-break: break-all;
  color: white;
}
.alertMsg .msg::-webkit-scrollbar {
  display: none;
}
.alertMsg .close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
}
.alertMsg .close-btn:hover {
  background: white;
  border-radius: 5px;
  color: darkgreen;
  font-weight: bold;
}
.alertMsg.Show {
  animation: Show_slide 1s ease forwards;
}
.alertMsg.hide {
  display: none;
}
.alertMsg.hide {
  animation: hide_slide 1s ease forwards;
}
@keyframes Show_slide {
  0% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-10px);
  }
}
@keyframes hide_slide {
  0% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(0%);
  }

  80% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(100%);
  }
}
/* =========================================== Dialog Message Alert Style ======================= */
.Msg-Popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopupClose {
  display: none;
}
.Msg-Container {
  height: 150px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0 5px 5px 0 lightblue;
}
.Msg-Header {
  height: 35px;
  background: rgb(250, 251, 250);
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  border-radius: 10px;
  border-bottom: 1px solid rgb(230, 226, 226);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Msg-Header i {
  cursor: pointer;
  color: darkgreen;
}
.Msg-Header .MsgTooltip {
  display: none;
}
.Msg-Header .MsgClose {
  display: none;
}
.Msg-Header .MsgTooltip.active {
  display: block;
  z-index: 1;
  position: absolute;
  padding-top: 20px;
  margin-left: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #464270;
  border-radius: 7px;
  padding: 3px;
}
.Msg-Header .MsgClose.Close {
  position: absolute;
  z-index: 1;
  text-transform: capitalize;
  color: #464270;
  border-radius: 50%;
  font-size: 25px;
}
.Msg-Header i:nth-child(2):hover {
  color: #fff;
  border-radius: 50%;
  background-color: darkgreen;
}
.Msg-Body {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #464270;
  padding: 0 5px;
  white-space: wrap;
}
.Msg-Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  height: 30px;
  padding: 0 10px;
}
.Msg-Footer button {
  width: 100px;
  font-size: 15px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #464270;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  box-shadow: none;
  font-weight: 700;
}
.Msg-Footer button:hover {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}
.Msg-Footer button:focus {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}

/*======================LoadingStyle Design=======================*/
.Loading-Popup {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  position: fixed;
  background: #00000050;
}
.Loading-Design {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}
@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}
@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.Loading-Design span {
  position: absolute;
  top: 75px;
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}
.Loading {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.Loading-Background {
  height: 50px;
  width: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.Loading span {
  font-size: 20px;
  padding-left: 10px;
  color: black;
  font-weight: bold;
}
.Loading-Circle {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid darkgreen;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* =======Loading Animation ===========*/
#panel {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  z-index: 1;
}
#panel:hover {
  border-color: #444;
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  display: block;
  position: absolute;
  margin: 0;
  width: 5px;
  height: 5px;
}
#loading8-1 #CircleBottom,
#loading8-2 #CircleMiddle,
#loading8-3 #CircleTop,
#loading8-4 #CircleRight,
#loading8-5 #CircleRight1,
#loading8-6 #CircleRight2 {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
#loading8-1 #CircleBottom #ball,
#loading8-2 #CircleMiddle #ball,
#loading8-3 #CircleTop #ball,
#loading8-4 #CircleRight #ball,
#loading8-5 #CircleRight1 #ball,
#loading8-6 #CircleRight2 #ball {
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  background-color: #06c;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 0 0 0 20px;
}
#loading8-1 #CircleBottom {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
#loading8-2 #CircleMiddle {
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
}
#loading8-3 #CircleTop {
  -webkit-transform: rotate(100deg);
  -moz-transform: rotate(100deg);
  -o-transform: rotate(100deg);
  -ms-transform: rotate(100deg);
  transform: rotate(100deg);
}
#loading8-4 #CircleRight {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  -o-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
#loading8-5 #CircleRight1 {
  -webkit-transform: rotate(200deg);
  -moz-transform: rotate(200deg);
  -o-transform: rotate(200deg);
  -ms-transform: rotate(200deg);
  transform: rotate(200deg);
}
#loading8-6 #CircleRight2 {
  -webkit-transform: rotate(250deg);
  -moz-transform: rotate(250deg);
  -o-transform: rotate(250deg);
  -ms-transform: rotate(250deg);
  transform: rotate(250deg);
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  -webkit-animation: cwSpin 1.3s linear 0.3s infinite;
  -moz-animation: cwSpin 1.3s linear 0.3s infinite;
  -o-animation: cwSpin 1.3s linear 0.3s infinite;
  -ms-animation: cwSpin 1.3s linear 0.3s infinite;
  animation: cwSpin 1.3s linear 0.3s infinite;
}
@-webkit-keyframes cwSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cwSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes cwSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes cwSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes cwSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#PrintPdf {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
  padding: 0;
  border: 1px solid;
}
